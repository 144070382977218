<template>
  <vs-card class="emailCard">
    <h4 class="mb-6 mt-2">Push Notification Details</h4>
    <vs-form>
      <vs-label class="label">Title</vs-label>
      <vs-textarea class="col-sm-3" v-model="pages.title"></vs-textarea>
    </vs-form>
    <vs-form>
      <vs-label class="label">Message</vs-label>
      <vs-textarea class="col-sm-3" v-model="pages.message"></vs-textarea>
    </vs-form>
    <vs-form>
      <vs-label class="label">Slug</vs-label>
      <vs-textarea class="col-sm-3" v-model="pages.slug"></vs-textarea>
    </vs-form>
    <vs-form>
      <vs-label class="label">Type</vs-label>
      <vs-textarea class="col-sm-3" v-model="pages.type"></vs-textarea>
    </vs-form>
    <!-- <div class="editEmail">
      <vs-label class="label">Email Content</vs-label>
    </div>
    <vs-label class="label">Available Linked Content</vs-label>
    <div class="linkedContent">
      <ul>
        <li
          v-for="(keyword, index) in pages.keywords"
          :key="index"
          class="keywords"
        >
          {{ keyword }}
        </li>
      </ul>
    </div>
    <div class="editEmail">
      <vs-label class="label">Message</vs-label>
      <vs-textarea :pages="pages"></vs-textarea>
    </div>
    <div class="editEmail">
      <vs-label class="label"> Footer Content (Optional)</vs-label>
      <quillEditor :pages="pages" type="emailTemplateFooter"></quillEditor>
    </div> -->
    <div slot="footer" class="mb-12 mt-12">
      <vs-row>
        <vs-button @click="saveEmailDetails" align="left"
          >Save Details</vs-button
        >
      </vs-row>
    </div>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import quillEditor from "../../Editor";

export default {
  name: "pushNotificationViewEditDetail",
  components: {
    quillEditor
  },
  data: () => ({
    pages: {
      content: "",
      footer: "",
      subject: ""
    }
  }),
  methods: {
    ...mapActions("email", ["fetchPushNotificationTemplateDetails", "updatePushNotificationTemplateDetails"]),
    getEmailDetail(id) {
      let self = this;
      this.fetchPushNotificationTemplateDetails(id).then(res => {
        this.pages.content = res.data.data.content;
        this.pages.subject = res.data.data.subject;
        this.pages.footer = res.data.data.footer;
        this.pages = res.data.data;
      });
    },
    saveEmailDetails() {
      let id = this.$route.params.id;
      let info = this.pages;
      this.updatePushNotificationTemplateDetails({ id, info }).then(res => {
        this.$vs.notify({
          subject: "Update Page Content",
          text: res.data.message,
          // iconPack: 'feather',
          // icon: 'icon-alert-circle',
          color: "success"
        });
        //this.$router.push({name: 'email-template'});
      });
    }
  },
  created() {
    let pageId = this.$route.params.id;
    this.getEmailDetail(pageId);
  }
};
</script>
<style>
.ql-editor {
  min-height: 200px;
}
ul {
  list-style: none;
  margin-left: 0px;
}
.keywords {
  display: inline-block;
  border: 1px solid dimgrey;
  margin-right: 3px;
  padding: 5px;
  color: white;
  background-color: #3dc9b3;
}
.label {
  font-size: small;
  color: black;
}
.editEmail {
  padding-top: 20px;
  padding-bottom: 20px;
}
.emailCard {
  min-height: 650px;
  padding-left: 20px;
}
</style>
